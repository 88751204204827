/* eslint-disable  no-unused-expressions */

export { default as isMobile } from './isMobile';
export { default as isTablet } from './isTablet';
export { default as addActiveLinkClass } from './addActiveLinkClass';
export { default as isTouchDevice } from './isTouchDevice';
export { default as hiddenFromIndexLink } from './hiddenFromIndexLink';

export function debounce(func, wait, immediate) {
  let timeout;
  return function () { // eslint-disable-line
    const context = this;
    const args = arguments; // eslint-disable-line
    const later = function () { // eslint-disable-line
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function wrap(el, wrapper) {
  el.parentNode.insertBefore(wrapper, el);
  wrapper.appendChild(el);
}

export const wrapAll = (query, tag) => {
  document.querySelectorAll(query).forEach((elem) => {
    const div = document.createElement(tag);
    elem.parentElement.insertBefore(div, elem);
    div.appendChild(elem);
  });
};

export function wrapInner(parent, wrapper) {
  if (typeof wrapper === 'string') wrapper = document.createElement(wrapper); // eslint-disable-line

  parent.appendChild(wrapper);

  while (parent.firstChild !== wrapper) wrapper.appendChild(parent.firstChild);
}

export function slideUp(block) {
  block.style.height = '0'; // eslint-disable-line
}

export function slideDown(block) {
  block.style.height = `${block.children[0].offsetHeight}px`; // eslint-disable-line
}

export function toggleSlide(block) {
  !block.offsetHeight ? slideDown(block) : slideUp(block);
}
