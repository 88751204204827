const hiddenFromIndexLink = () => {
  const linksArray = document.querySelectorAll('[data-link]');
  if (linksArray) {
    linksArray.forEach((link) => {
      link.addEventListener('click', (e) => {
        const url = e.currentTarget.getAttribute('data-link');
        window.location.href = `/${url}`;
      });
    });
  }
};

export default hiddenFromIndexLink;
